*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}
.vm-tv-screen-effect{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .vm-tv-screen-effect::after {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(18, 16, 16, 0.1);
    opacity: 0;
    z-index: 2;
    pointer-events: none;
  }
  .vm-tv-screen-effect::before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
    z-index: 2;
    background-size: 100% 2px, 3px 100%;
    pointer-events: none;
  }
  .loadingmain {
    position: absolute;
    z-index: 50;
    left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%;
  background: rgb(255,0,0);
  background: linear-gradient(119deg, rgba(255,0,0,1) 0%, rgba(255,222,0,1) 100%);
  transition: opacity 1s;
  opacity: 1;
  animation-name: example;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  background-size: 200% 200%;
  }
  @keyframes example {
    0% {background-position: 0% 50%;}
    50% {background-position: 100% 50%;}
    100% {background-position: 0% 50%;}
  }
  .loadingcontent {
    position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -101px;
  margin-left: -157px;
  justify-content: center;
  width: 190px;
  }
  .loader {
    border: 32px solid #f3f3f3; /* Light grey */
    border-right: 32px solid #000000; /* Blue */
    border-radius: 50%;
    width: 0px;
    height: 0px;
    margin: auto;
    margin-bottom: 20px;
    animation: spin 1s cubic-bezier(.61,.18,.54,.81) infinite;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  a {
    color: #000000;
    background-color: #ffffff;
    padding: 10px;
    border-radius: 50px;
    font-size: 20px;
    text-decoration: none;
    text-align: center;
    width: auto;
    margin: auto;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
  }
  .enterbutton {
    color: #000000;
    background-color: #ffffff;
    padding: 10px;
    border-radius: 50px;
    font-size: 20px;
    text-decoration: none;
    text-align: center;
    margin: auto;
    margin-top: 20px;
    opacity: 0;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    transition: opacity 0.4s;
  }
  p {
    font-family: 'Roboto', sans-serif;
    color: black;
    background-color: white;
    border-radius: 20px;
    padding: 10px;
    margin: 10px;
    text-align: center;
    width: 200px;
    position: absolute;
    top: 0;
    left: 0;
  }
  #controls {
    position: absolute; 
    bottom: 0;
    left: 0;
    width: 300px;
    margin: 10px;
  }